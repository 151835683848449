import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import LoginPage from '../views/LoginPage.vue'
import ProductsPage from '../views/ProductsPage.vue'
import CustomersPage from '../views/CustomersPage.vue'
import CustomerShow from '../views/CustomerShow.vue'
import OrderShow from '../views/OrderShow.vue'
import ExportPage from '../views/ExportPage.vue'
import DouzaineProduct from '../views/DouzaineProduct.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'RootPage',
        component: HomePage
    },
    {
        path: '/home',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/products',
        name: 'ProductsPage',
        component: ProductsPage
    },
    {
        path: '/customers',
        name: 'CustomersPage',
        component: CustomersPage
    },
    {
        path: '/customers/:id',
        name: 'CustomerShow',
        component: CustomerShow
    },
    {
        path: '/orders/:id',
        name: 'OrderShow',
        component: OrderShow
    }, 
    {
        path: '/export',
        name: 'ExportPage',
        component: ExportPage
    }, 
    {
      path: '/douzaine-products',
      name: 'DouzaineProduct',
      component: DouzaineProduct
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
