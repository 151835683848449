import Vue from "vue";
window.Vue = require("vue");
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require("popper.js").default;
    window.$ = window.jQuery = require("jquery");

    require("bootstrap");
} catch (e) { }

import "bootstrap/dist/css/bootstrap.min.css";

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Content-Type"] = "application/json";
window.axios.defaults.headers.common["Accept"] = "application/json";
window.axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://localhost:3000/api/v1': 'https://laravel.vitaminelocale.ch/api/v1';

const token = localStorage.getItem("token");
if (token) {
    window.axios.defaults.headers.common["Authorization"] = 'Bearer ' + JSON.parse(token);
}

window.events = new Vue();
window.flash = function (message, level = "success") {
    window.events.$emit("flash", { message, level });
};

import VModal from "vue-js-modal";
Vue.use(VModal);

import VueJsonPretty from 'vue-json-pretty';
Vue.component("vue-json-pretty", VueJsonPretty); 

import Switches from 'vue-switches';
Vue.component('switches', Switches);

import JsonCSV from 'vue-json-csv'; 
Vue.component('downloadCsv', JsonCSV); 

/*
import Echo from "laravel-echo"
window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '04598a3f578b4bfae3f3',
    cluster: 'eu',
    encrypted: true
});
*/


