<template>
    <div>
        <div class="text-right mb-4">
            <select :value="tour" @input="selectTour">
                <option v-for="item in tourOptions" :key="item" :value="item">
                    {{ item }}
                </option>
            </select>
        </div>
        <table class="table">
            <thead>
                <th>Client</th>
                <th>Commandes</th>
                <th>Ordre</th>
                <th>Heures</th>
                <th>Actions</th>
            </thead>
            <tbody>
                <tr v-for="customer in customers" :key="customer.id">
                    <td class="pointer" @click="$modal.show('customer_modal', {customer: customer})">
                        {{ customer.id }} {{ customer.last_name }}
                        {{ customer.first_name }}
                    </td>
                    <td>
                        <ul class="list-unstyled">
                            <!-- orders douzaines-->
                            <li
                                v-for="order in customer.orders_d"
                                :key="order.id"
                            >
                                <span class="mr-4">{{ order.id }}</span>
                                <span>
                                    <i
                                        class="fas fa-check-circle text-success"
                                        v-if="order.ready"
                                    ></i>
                                </span>
                                <span class="d-none">
                                    {{
                                        get_employee_username(order.employee_id)
                                    }}</span
                                >
                            </li>
                            <!-- orders -->
                            <li
                                v-for="order in customer.orders"
                                :key="order.id"
                            >
                                <span class="mr-4">{{ order.id }}</span>
                                <span v-if="order.finished">
                                    <i
                                        class="fas fa-exclamation-triangle text-warning"
                                        v-if="order.lack"
                                    ></i>
                                    <i
                                        class="fas fa-check-circle text-success"
                                        v-else
                                    ></i>
                                </span>
                                <span class="d-none">
                                    {{
                                        get_employee_username(order.employee_id)
                                    }}</span
                                >
                            </li>
                        </ul>
                    </td>
                    <td>{{ customer.mapotempo.order }}</td>
                    <td>{{ customer.mapotempo.hour }}</td>
                    <td>
                        <i
                            class="fas fa-shopping-basket text-primary"
                            @click="redirect(customer.id)"
                        ></i>
                    </td>
                </tr>
            </tbody>
        </table>

        <modal
            name="customer_modal"
            :width="windowWidth > 420 ? '400' : '100%'"
            height="auto"
            :scrollable="true"
            @before-open="(event)=>{ customer=event.params.customer}"
        >
            <div class="p-4" v-if="customer">
                <h5>
                    {{ customer.id }}
                    {{ customer.last_name }}
                    {{ customer.first_name }}
                </h5>

                <div class="mb-2">{{ customer.phone }}</div>
                <div class="mb-4">{{ customer.email }}</div>

                <div class="text-secondary">
                    {{ customer.mapotempo && customer.mapotempo.tour }}
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            customers: [],
            customer: null
        };
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            let tour = this.tour;
            axios
                .get(`/tour/${tour}/customers`)
                .then(({ data }) => {
                    console.log(data);
                    if (data.customers) {
                        data.customers.sort(function (a, b) {
                            return a.mapotempo.order - b.mapotempo.order;
                        });
                        this.customers = data.customers;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        redirect(customer_id) {
            this.$router.push({
                name: "CustomerShow",
                params: { id: customer_id },
            });
        },
        selectTour(e) {
            let tour = e.target.value;
            this.$store.commit("tourMutation", tour);
            this.fetch();
        },
    },
};
</script>

<style>
</style>