<template>
  <div>
    <input
      type="checkbox"
      v-model="hide_tours"
    > <label for="hide_tours">masquer les volumes par tournée</label>

    <div class="d-flex justify-content-end">      
      <div
        v-for="size in sizes"
        :key="size.id"
        class="mr-2"
      >
        <span>{{ size.name }}</span>
      </div>
    </div>

    <!-- douzaine products table -->
    <table
      class="table table-responsive table-borderless douzaine-products"
      v-if="loaded"
    >
      <thead>
        <tr>
          <th>Produit</th>
          <th>prix</th>
          <th>unité</th>
          <th
            v-for="douzaine in douzaines"
            :key="douzaine.id"
          >
            {{ douzaine.name }}
          </th>
          <th>Total</th>
        </tr>

        <!-- row of price, purchase ratio and buget -->
        <tr v-if="development">
          <td
            colspan=3
            class="text-right"
          >Prix<br />
            Ratio d'achat<br />
            Budget d'achat
          </td>
          <td
            v-for="douzaine in douzaines"
            :key="douzaine.id"
          >
            <div class="d-flex">
              <div
                class="fix-width"
                v-for="size in sizes"
                :key="size.id"
              >
                <div>{{ getDouzainePrice(douzaine, size.id) }}</div>
                <purchase-ratio-input
                  :douzaine_id="douzaine.id"
                  :size_id="size.id"
                  :purchase_ratio="find_purchase_ratio(douzaine.id, size.id)"
                  @updated="update_purchase_ratio"
                />
                <div>{{ floatDisplay(getBuget(douzaine, size.id)) }}</div>
              </div>
            </div>
          </td>
          <td></td>
        </tr>

        <!-- row of quantity of panier and price of panier -->
        <tr>
          <td colspan=3 class="text-right">   
            Total de panier par semaine <br />    
            Prix de panier selons planification    
          </td>
          <td
            v-for="douzaine in douzaines"
            :key="douzaine.id"
          >
            <div class="d-flex">
              <span
                class="fix-width"
                v-for="size in sizes"
                :key="size.id"
              >
                <div>{{ sum_orders_douzaine(douzaine.id, size.id) }}</div>
                <div>{{ price_sum(douzaine.id, size.id) }}</div>
              </span>
            </div>
          </td>
          <td></td>
        </tr>

        <!-- douzaine order quantities -->
        <tr v-show="!hide_tours">
          <td colspan=2>Total de panier par tour</td>
          <td>
            <div
              v-for="tour in tours"
              :key="tour"
              class="nowrap"
            >
              {{ display(tour) }}
            </div>
          </td>
          <td
            v-for="douzaine in douzaines"
            :key="douzaine.id"
          >
            <div
              v-for="tour in tours"
              :key="tour"
              class="d-flex"
            >
              <div
                class="fix-width"
                v-for="size in sizes"
                :key="size.id"
              >
                {{ quantities[douzaine.id][size.id][tour] }}
              </div>
            </div>
          </td>
          <td></td>
        </tr>

      </thead>
      <tbody>
        <tr
          v-for="product in products"
          :key="product.id"
        >
          <td>
            <product-info :product="product" />
          </td>
          <td>{{ product.price }}</td>
          <td>{{ product.unit }}
            <div
              v-show="!hide_tours"
              class="mt-4"
            >
              <div
                v-for="tour in tours"
                :key="tour"
                class="nowrap"
              >
                {{ display(tour) }}
              </div>
            </div>
          </td>
          <td
            v-for="douzaine in douzaines"
            :key="douzaine.id"
          >
            <div class="d-flex">
              <div
                v-for="size in sizes"
                :key="size.id"
              >
                <douzaine-product-quantity
                  :douzaine_id="douzaine.id"
                  :product_id="product.id"
                  :size_id="size.id"
                  :douzaine_product="find_douzaine_product(douzaine.id, size.id, product.id)"
                  @updated="update_douzaine_products"
                />
              </div>
            </div>
            <div
              v-show="!hide_tours"
              class="mt-4"
            >
              <div
                v-for="tour in tours"
                :key="tour"
                class="d-flex"
              >
                <div
                  class="fix-width"
                  v-for="size in sizes"
                  :key="size.id"
                >

                  {{ floatDisplay(quantity_tour(douzaine.id, size.id, tour, product.id)) }}
                  <span v-if=false>{{ sum_orders(douzaine.id, size.id, tour) }} |</span>
                </div>
              </div>
            </div>
          </td>
          <th class="text-right">
            {{ floatDisplay(quantity_tour_cum(product.id)) }}
            <div
              v-show="!hide_tours"
              class="mt-4"
            >
              <div
                v-for="tour in tours"
                :key="tour"
                class="text-right"
              >
                {{ floatDisplay(quantity_tour_sum(tour, product.id)) }}
              </div>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      products: [],
      douzaines: [],
      sizes: [],
      douzaine_products: [],
      // tour info
      hide_tours: false,
      orders_d: [],
      quantities: [],
      // purchase ratio
      purchase_ratios: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loaded = false;
      axios.get("/plan/douzaines").then(({ data }) => {
        if (data.products) this.products = data.products;
        if (data.douzaines) this.douzaines = data.douzaines;
        if (data.sizes) this.sizes = data.sizes;
        if (data.douzaine_products) this.douzaine_products = data.douzaine_products;
        if (data.orders_d) this.orders_d = data.orders_d;
        if (data.quantities) this.quantities = data.quantities;
        if (data.purchase_ratios) this.purchase_ratios = data.purchase_ratios; 
        // console.log(data.orders_d);
        // console.log(data.quantities);
        // console.log('purchase ratios', data.purchase_ratios); 
        this.loaded = true;
      });
    },

    find_douzaine_product(douzaine_id, size_id, product_id) {
      return this.douzaine_products.find(
        (elem) => elem.douzaine_id == douzaine_id && elem.size_id == size_id && elem.product_id == product_id
      );
    },

    update_douzaine_products(data) {
      let ids = data.map((elem) => elem.id);
      let filtered = this.douzaine_products.filter(
        (elem) => !ids.includes(elem.id)
      );
      this.douzaine_products = [...filtered, ...data];
    },
    // for table head, to calculate price for the panier
    price_sum(douzaine_id, size_id) {
      const items = this.douzaine_products.filter(elem => elem.douzaine_id == douzaine_id && elem.size_id == size_id);
      let prices = [];
      for (const item of items) {
        let product = this.products.find(elem => elem.id == item.product_id);
        if (product && product.price && item.quantity) {
          prices.push(product.price * item.quantity);
        }
      }
      const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue);
      const price = prices.reduce(reducer, 0);
      return price.toFixed(2);
    },

    sum_price_product(product_id) {
      const items = this.douzaine_products.filter(elem => elem.product_id == product_id);
      let product = this.products.find(elem => elem.id == product_id);
      if (!(product && product.price)) return;
      let prices = [];
      for (const item of items) {
        if (item.quantity) {
          prices.push(product.price * item.quantity);
        }
      }
      const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue);
      const price = prices.reduce(reducer, 0);
      return price.toFixed(2);
    },

    display(tour) {
      return tour.replace('Tournée ', 'T');
    },

    // orders 
    // this function is replaced by backend, with data 'quantities'
    sum_orders(douzaine_id, size_id, tour) {
      const items = this.orders_d.filter(elem => elem.douzaine_id == douzaine_id && elem.size_id == size_id && elem.tour == tour);
      return items.length;
    },

    // for table head
    sum_orders_douzaine(douzaine_id, size_id) {
      const items = this.orders_d.filter(elem => elem.douzaine_id == douzaine_id && elem.size_id == size_id);
      return items.length;
    },

    quantity_tour(douzaine_id, size_id, tour, product_id) {
      const douzaine_product = this.find_douzaine_product(douzaine_id, size_id, product_id);
      if (douzaine_product && douzaine_product.quantity) {
        return douzaine_product.quantity * this.quantities[douzaine_id][size_id][tour];
      }
    },

    quantity_tour_sum(tour, product_id) {
      let result = 0;
      for (const douzaine of this.douzaines) {
        for (const size of this.sizes) {
          let quantity = this.quantity_tour(douzaine.id, size.id, tour, product_id);
          if (quantity) result = Number(result) + Number(quantity);
        }
      }
      return result;
    },

    quantity_tour_cum(product_id) {
      let result = 0;
      for (const tour of this.tours) {
        let sum = this.quantity_tour_sum(tour, product_id);
        if (sum) result = Number(result) + Number(sum);
      }
      return result;
    },

    // purchase ratio
    getDouzainePrice(douzaine, size_id) {
      if (size_id == 1) return douzaine.price_medium;
      if (size_id == 2) return douzaine.price_small;
      if (size_id == 3) return douzaine.price_large;
    },

    find_purchase_ratio(douzaine_id, size_id) {
      return this.purchase_ratios.find(
        (elem) => elem.douzaine_id == douzaine_id && elem.size_id == size_id
      );
    },

    update_purchase_ratio(data) {
      let ids = data.map((elem) => elem.id);
      let filtered = this.purchase_ratios.filter(
        (elem) => !ids.includes(elem.id)
      );
      this.purchase_ratios = [...filtered, ...data];
    }, 

    getBuget(douzaine, size_id){
      const price = this.getDouzainePrice(douzaine, size_id); 
      const purchase_ratio = this.find_purchase_ratio(douzaine.id, size_id); 
      if( price && purchase_ratio && purchase_ratio.ratio>0 ){
        console.log( price, purchase_ratio); 
        return price/purchase_ratio.ratio; 
      }
    }

  },
};
</script>

<style>
</style>