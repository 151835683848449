<template>
    <div v-if="product">
        <div v-if="attribute">{{ product[attribute] }}</div>

        <div class="d-flex flex-wrap" v-else>
            <img class="thumb mr-md-4" :src="product.thumb_asset"  />
            <div>
                {{ product.category && product.category.name }} -
                {{ product.name }}
                <div class="text-secondary">
                    {{ product.provider.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["product", "attribute"],
};
</script>

<style>
</style>