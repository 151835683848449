<template>
    <div>checking token</div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {
        this.check_token();
    },
    methods: {
        check_token() {
            console.log("check token");
            const token = window.localStorage.getItem("token");
            if (token) {
                axios
                    .get("/check/token")
                    .then(() => {
                        console.log("success");
                        this.$router.push({ name: "CustomersPage" });
                    })
                    .catch(() => {
                        console.log("error");
                        this.$router.push({ name: "LoginPage" });
                    });
            } else this.$router.push({ name: "LoginPage" });
        },
    },
};
</script>

<style>
</style>