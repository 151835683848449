<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <login-form @success="login_success" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    login_success() {
      console.log('login success');
      this.$router.push({ name: 'HomePage' });  //  can only push to routes without beforeEnter
      // window.location.replace("/home"); 
    }
  }
}
</script>
