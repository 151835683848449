<template>
    <div>
        {{ username }}  {{ order_id }}
    </div>
</template>
<script>
export default {
    data() {
        return {
            username: '', 
            order_id: null,
        };
    },
    created(){
        this.listen(); 
    },
    methods: {
        listen() {
            Echo.channel("vitalog").listen("StartedOrder", (e) => {
                console.log('listen', e); 
                this.username = e.username; 
                this.order_id = e.order_id; 
            });
        },
    },
};
</script>

<style>
</style>